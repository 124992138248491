import { Component, OnInit } from "@angular/core";
import { Editorial } from "src/app/interfaces/editorial";

@Component({
    selector: "app-editorial-board",
    templateUrl: "./editorial-board.component.html",
    styleUrls: ["./editorial-board.component.scss"],
})
export class EditorialBoardComponent implements OnInit {
    editorialBoard: Editorial[] = [
        {
            id: 1,
            name: "Dr. Abdel Fattah Qaraman",
            job: "Member of the Journal's Editorial Board",
            bio: "Abdel Fatah Qaraman obtained his Ph.D. in Inorganic and Analytical Chemistry from Ain Shams University in Egypt in 2015, his MSc in Analytical Chemistry from Al-Azhar University in Palestine in 2006, and his BSc in Chemistry from Bethlehem University in Palestine in 1994. He is currently the dean of the faculty of intermediate studies at Israa University-Palestine. His scientific research has contributed to significant results and findings in different fields which were published in numerous scientific international journals and conferences. His current research interests include the development of thermal insulation materials and occupational safety.",
            image: "assets/img/editorial/Dr. Abdel Fattah Qaraman.png",
        },
        {
            id: 2,
            name: "Dr. Maher A. Elbayoumi",
            job: "Member of the Journal's Editorial Board",
            bio: "No info yet.",
            image: "https://via.placeholder.com/600",
        },
        {
            id: 3,
            name: "Dr. Ahmed Almassri",
            job: "Member of the Journal's Editorial Board",
            bio: "Ahmed Almassri received a B.E. in Electrical Engineering in 2012 and an MSc in Control and Automation Engineering from the University Putra Malaysia in 2015. He earned dual Ph.D. degrees in Biomedical Engineering from the University Putra Malaysia and the Graduate School of Life Science and Systems Engineering at Kyushu Institute of Technology, Japan, in 2019. He worked as a Postdoctoral researcher at the Department of Graduate School of Life Science and Systems Engineering, Kyushu Institute of Technology, Japan, for 4 years. Additionally, he served as the Director of Scientific Research and Representative of Israa University, Palestine. Currently, he holds the position of Assistant Professor at Toyama Prefectural University. During six years of academic training and five years of professional experience, he has published 18 academic papers (including journal articles, conference papers, and abstract papers) and participated in 20 Professional Conferences in various countries. His research interests encompass Robotics, Biomedical Engineering, Neural Networks, Sensors, and Control and Automation.",
            image: "assets/img/editorial/Dr. Ahmed Almassri.png",
        },
        {
            id: 4,
            name: "Dr. Ahmed H. Albelbeisi",
            job: "Member of the Journal's Editorial Board",
            bio: "No info yet.",
            image: "https://via.placeholder.com/600",
        },
    ];
    constructor() {}

    filteredEditorialBoard = [];
    ceo: any;

    ngOnInit(): void {
        this.filteredEditorialBoard = this.editorialBoard.filter(
            (editorial) => editorial.id >= 2
        );

        this.ceo = this.editorialBoard.find((editorial) => editorial.id === 1);
    }
}
