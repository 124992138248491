<app-header /> <app-home-main-banner />

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact Us</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class="bx bx-envelope"></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:hello@ednuv.com">hello@ednuv.com</a></p>
                    <p><a href="mailto:ednuv@hello.com">ednuv@hello.com</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class="bx bx-map"></i>
                    </div>
                    <h3>Location Here</h3>
                    <p>
                        <a
                            href="https://goo.gl/maps/Mii9keyeqXeNH4347"
                            target="_blank"
                            >2750 Quadra Street Victoria Road, New York, United
                            States</a
                        >
                    </p>
                </div>
            </div>
            <div
                class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
            >
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class="bx bx-phone-call"></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:1234567890">+123 456 7890</a></p>
                    <p><a href="tel:2414524526">+241 452 4526</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Drop us Message for any Query</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input
                                required
                                minlength="3"
                                maxlength="50"
                                ngModel
                                name="name"
                                type="text"
                                #name="ngModel"
                                class="form-control"
                                id="name"
                                placeholder="Your name"
                            />
                            <div
                                class="alert alert-danger"
                                *ngIf="name.touched && !name.valid"
                            >
                                <div *ngIf="name.errors.required">
                                    Name is required.
                                </div>
                                <div *ngIf="name.errors.minlength">
                                    Name is minimum
                                    {{
                                        name.errors.minlength.requiredLength
                                    }}
                                    character.
                                </div>
                                <div *ngIf="name.errors.maxlength">
                                    Name is maximum 50 character.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input
                                required
                                ngModel
                                name="email"
                                type="text"
                                #email="ngModel"
                                class="form-control"
                                id="email"
                                placeholder="Your email address"
                            />
                            <div
                                class="alert alert-danger"
                                *ngIf="email.touched && !email.valid"
                            >
                                Email Address is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input
                                required
                                ngModel
                                name="number"
                                type="text"
                                #number="ngModel"
                                class="form-control"
                                id="number"
                                placeholder="Your phone number"
                            />
                            <div
                                class="alert alert-danger"
                                *ngIf="number.touched && !number.valid"
                            >
                                Phone number is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input
                                required
                                ngModel
                                name="subject"
                                type="text"
                                #subject="ngModel"
                                class="form-control"
                                id="subject"
                                placeholder="Your subject"
                            />
                            <div
                                class="alert alert-danger"
                                *ngIf="subject.touched && !subject.valid"
                            >
                                Phone subject is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea
                                required
                                ngModel
                                #message="ngModel"
                                name="message"
                                id="message"
                                cols="30"
                                rows="5"
                                class="form-control"
                                placeholder="Your message..."
                            ></textarea>
                            <div
                                class="alert alert-danger"
                                *ngIf="message.touched && !name.valid"
                            >
                                Message is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button
                            type="submit"
                            class="default-btn"
                            [class.disabled]="!contactForm.valid"
                        >
                            <i class="bx bx-paper-plane icon-arrow before"></i
                            ><span class="label">Send Message</span
                            ><i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image">
        <img src="assets/img/map.png" alt="image" />
    </div>
</div>

<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d359536.14502592356!2d-76.08043413946686!3d45.25029749557496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce04ff4fe494ef%3A0x26bb54f60c29f6e!2sParliament%20Hill!5e0!3m2!1sen!2sbd!4v1593691677634!5m2!1sen!2sbd"
    ></iframe>
</div>
