<app-header />

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Proceedings Series</li>
            </ul>
            <h2>Proceedings Series</h2>
        </div>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of list">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/journals/{{ item.id }}" class="d-block">
                            <img
                                [src]="baseUrl + '/images/' + item.image"
                                alt="image"
                            />
                        </a>
                        <div class="tag">
                            <a routerLink="/journals/{{ item.id }}">IUJAS</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <h3>
                            <a
                                routerLink="/journals/{{ item.id }}"
                                class="d-inline-block"
                            >
                                {{ item.title }}</a
                            >
                        </h3>
                        <a
                            routerLink="/journals/{{ item.id }}"
                            class="read-more-btn"
                            >Read More <i class="bx bx-right-arrow-alt"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
