<app-header></app-header>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Reviewers</li>
            </ul>
            <h2>Team of Reviewers</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <h3>Annual List of Reviewers 2022</h3>
            <p>
                On Behalf of the Editorial Team of (IUJAS), we would like to
                express our heartfelt gratitude for your invaluable
                contributions as reviewers throughout the year 2022. Your
                dedication and expertise have played a crucial role in
                maintaining the high standards of our journal.
            </p>
            <h4>
                Once again, we thank the following reviewers for their
                significant contributions to (IUJAS) in 2022:
            </h4>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor1.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Fazlullah Khan</a
                            >
                        </h3>
                        <span>University of Arizona, USA</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor2.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Mohammed Bule</a
                            >
                        </h3>
                        <span>Ambo University, Ethiopia</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor3.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Halgord Farag</a
                            >
                        </h3>
                        <span>Sulaimani polytechnic University, Iraq</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor4.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Edris Kakemam</a
                            >
                        </h3>
                        <span>Tabriz University of Medical Sciences, Iran</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor5.jpg"
                            alt="images"
                        />
                    </div>

                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Ali H. Albelbeisi</a
                            >
                        </h3>
                        <span
                            >Research Unit, Ministry of Health, Gaza Strip,
                            Palestine
                        </span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Iyad Shaqura</a
                            >
                        </h3>
                        <span
                            >Quality Unit, Ministry of Health, Gaza Strip,
                            Palestine</span
                        >
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Heba Arafat</a
                            >
                        </h3>
                        <span>Al-Azhar University, Gaza Strip, Palestine </span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Ameer Shehada</a
                            >
                        </h3>
                        <span
                            >Medical Services Directorate, Gaza Strip,
                            Palestine</span
                        >
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Saib Al-Iwini</a
                            >
                        </h3>
                        <span>Islamic University of Gaza, Palestine </span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Mazen M. Al Zaharna</a
                            >
                        </h3>
                        <span>Islamic University of Gaza, Palestine</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Abdel Hamid El Bilbeisi</a
                            >
                        </h3>
                        <span>Al-Azhar University, Gaza Strip, Palestine</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Ismail Aburkab</a
                            >
                        </h3>
                        <span
                            >Directorate of Mental Health, Ministry of Health,
                            Palestine</span
                        >
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Marwan O. Jalambo</a
                            >
                        </h3>
                        <span
                            >Palestine Technical College, Gaza Strip,
                            Palestine</span
                        >
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Areefa SM Al-kasseh</a
                            >
                        </h3>
                        <span>Islamic University of Gaza, Palestine</span>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Sohib Abu Jayab
                            </a>
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Salem Amro</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Basam Tayeh</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Tamer Elsalabi</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Azam abu Habib</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Zakaria Heles</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Jaber Alkaseh</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Helme salem</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Sami Salama</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Suheir Harb</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Huda Lubad</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Khalid Elramlawi</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr. Mohamed J Basher</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Akram Altaher</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            src="assets/img/instructor/instructor6.jpg"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a routerLink="/single-instructor"
                                >Dr.Khaled Elmasri</a
                            >
                        </h3>
                        <ul class="social">
                            <li>
                                <a href="#" class="facebook" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="twitter" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="instagram" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" class="linkedin" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <h3>Recognition for reviewers</h3>
        <p>
            Reviewers play a significant role in improving the quality of
            content and contributing to the success of the publishing process.
            IUJAS recognizes reviewers for their critical role they play in the
            research process and wants to encourage good standards of review;
            here are some of the rewards that we offer our reviewers:
        </p>
        <ul>
            <li>
                APC discounts: Reviewers on IUJAS receive a 25% discount on
                charges if they submit an article as a corresponding author
                within a year of submitting their review.
            </li>
            <li>
                Reviewer certificates: Upon request IUJAS provides certificates
                for completed reviews.
            </li>
            <li>
                Annual list of reviewers: Once a year, reviewers on IUJAS are
                publicly listed in our annual list of reviewers, where we say
                thank you for your contribution to maintaining the quality of
                research literature.
            </li>
        </ul>
    </div>
</div>
